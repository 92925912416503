module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"backgroundColor":"none","maxWidth":900,"linkImagesToOriginal":false,"disableBgImage":true,"wrapperStyle":"margin: 1.5rem 0;"}}],"remarkPlugins":[null,null,null,[null,{"style":"smart"}],null]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-code-notes/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"notes","basePath":"/","gitRepoContentPath":"https://github.com/shovon/notes.salrahman.com/tree/master/notes/","showThemeInfo":true,"showDescriptionInSidebar":true,"openSearch":{"siteShortName":"Sal's code notes","siteUrl":"https://notes.salrahman.com","siteTags":"front-end","siteContact":"https://twitter.com/shovnr","siteDescription":"A place to jot down notes for my code"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sal's Code Notes","short_name":"CodeNotes","description":"Notes on code. My second brain.","start_url":"/","background_color":"hsl(210, 38%, 95%)","theme_color":"hsl(345, 100%, 69%)","display":"standalone","icon":"static/logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"0c29e6ba8368706d04df0e94f1deb6cc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/*","/tag/*"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"notes.salrahman.com","customDomain":"stats.salrahman.com"},
    }]
